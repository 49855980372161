<!--A SIGLA "KB" significa Kanban-->
<!--A SIGLA "CP" significa Conversa padrão-->
<template>
    <b-modal ref="modalCriarPrompt"
             modal-class="modalCriarPrompt"
             dialog-class="modalCriarPrompt"
             content-class="contentModalCriarPrompt"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Nova configuração"
             hide-footer
             hide-header-close
             no-close-on-backdrop
             size="lg">
        <b-row>
            <b-col>
                <label>Titulo</label>
                <input v-model="tituloGpt" type="text" class="imp_config_gpt" />
            </b-col>

        </b-row>
        <b-row>
            <b-col>
                <label>Texto Informativo</label>
                <b-form-textarea v-model="informativo" id="textarea-small"
                                 size="sm"
                                 rows="3"
                                 placeholder="Texto informativo"></b-form-textarea>
            </b-col>
            <b-col>
                <label>Texto prompt</label>
                <b-form-textarea v-model="prompt" id="textarea-small"
                                 size="sm"
                                 rows="3"
                                 placeholder="Texto prompt"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row style="margin-top:25px;">
            <b-col>
                <label>Comando para transferir o atendimento</label><br>
                <input v-model="comandoTransferir" type="text" class="imp_config_gpt" placeholder="ex: #atendimento" />
            </b-col>
            <b-col>
                <label>Comando para encerrar o atendimento</label><br>
                <input v-model="comandoFinalizar" type="text" class="imp_config_gpt" placeholder="ex: #finalizar" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div id="holdBtns">
                    <div id="btnCancelarCriarPrompt" class="btnModalCriarPrompt" @click="hide">Cancelar</div>
                    <div id="btnSalvarCriarPrompt" class="btnModalCriarPrompt" @click="CriarEditar">Confirmar</div>
                </div>
            </b-col>
        </b-row>
        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>
    </b-modal>
</template>
<script>
import axios from 'axios'
export default {
        name: 'ModalCriarFormulario',
        components: {

        },
        props: {

            dadosPrompt: { Titulo: "", Prompt: "", TextoInformativo: "", ComandoFinalizar: "", ComandoTransferir: "", Id: null }
            //formularioDados: { NomeFormulario: "", Descricao: "", DataCadastro: "", Id: null }
        },
        data() {
            return {
                loading: false,
                exibe: false,
                tituloGpt: null,
                informativo: '',
                prompt: '',
                comandoTransferir: '',
                comandoFinalizar: '',
                habilitarGpt: false,
                dadosSetores: null,
                setorSelecionado: null,
                dadosEnvio: null,
                promptId: null,
                opcoesBool: [{ valor: true, texto: 'Sim' }, { valor: false, texto: 'Não' }],

                setorFilter: null,
                dadosSalvos: null,
                resolver: null,
            }
        },
        methods: {
            show() {
                this.limparInputs();
                this.exibe = true;

                this.$nextTick(() => {
                   
                    if (this.dadosPrompt != null) {
                        this.tituloGpt = this.dadosPrompt?.Titulo;
                        this.informativo = this.dadosPrompt?.TextoInformativo;
                        this.prompt = this.dadosPrompt?.Prompt;
                        this.comandoFinalizar = this.dadosPrompt?.ComandoFinalizar;
                        this.comandoTransferir = this.dadosPrompt?.ComandoTransferir;
                        this.promptId = this.dadosPrompt?.Id;
                        this.dadosSalvos = this.dadosPrompt;
                    }
                });
                return new Promise(resolve => this.resolver = resolve);
            },
            hide() {
                this.exibe = false
                this.resolver(this.dadosSalvos);
                this.limparInputs();
            },
            limparInputs() {
                this.prompt = "";
                this.tituloGpt = "";
                this.informativo = "";
                this.comandoFinalizar = "";
                this.comandoTransferir = "";
                this.promptId = null;
                this.dadosSalvos = null;
                this.resolver = null;
            },

            CriarEditar() {
                $("#caixa_carregamento").show();
                var data = {
                    "Titulo": this.tituloGpt,
                    "Prompt": this.prompt,
                    "TextoInformativo": this.informativo,
                    "ComandoFinalizar": this.comandoFinalizar,
                    "ComandoTransferir": this.comandoTransferir,
                    "Id": this.promptId
                };
                this.dadosEnvio = data;
                if (this.dadosPrompt == null) { this.Criar(data) } else { this.Editar(data) }
                this.dadosSalvos.Titulo = this.tituloGpt;
                this.dadosSalvos.Prompt = this.prompt;
                this.dadosSalvos.TextoInformativo = this.informativo;
                this.dadosSalvos.ComandoFinalizar = this.comandoFinalizar;
                this.dadosSalvos.ComandoTransferir = this.comandoTransferir;
            },
            Criar(dados) {
                axios.post('/api/chatgpt/SalvarConfiguracaoGpt', dados)
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaNovoPrompt', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            Editar(dados) {
               
                axios.put('/api/chatgpt/EditarConfiguracaoGpt', dados)
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaEdicaoPrompt', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            }

        },

        created() {

        }
}
</script>

<style scoped>

    .inputPrompt {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
    }

        .inputPrompt:hover, .inputPrompt:focus {
            outline: none !important;
        }

    .labelPrompt {
        margin-top: 15px;
        font-size: 14px;
        color: #666;
        margin-bottom: 0px;
    }

    #holdBtns {
        display: block;
        float: right;
        margin: 20px 0px;
    }

    .btnModalCriarPrompt {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
    }

        .btnModalCriarPrompt:hover {
            opacity: 0.8;
        }

    #btnCancelarCriarPrompt {
        border-bottom: 2px solid #808080;
        margin-right: 15px;
    }

    #btnSalvarCriarPrompt {
        border-bottom: 2px solid transparent;
        background-color: #3adf5b;
        color: #FFF;
    }

    #cx_botoes_gpt {
        float: right;
        margin-top: 25px;
    }

    .imp_config_gpt {
        width: 100%;
    }
</style>