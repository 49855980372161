<template>
    <div>
        <b-modal ref="form-canal" size="lg"
                 hide-footer centered
                 title="Canal">

            <b-container fluid>

                <b-row cols="2" class="mb-4">

                    <b-col style="align-self: flex-end;">
                        <input-padrao classes="m-0" label="Descricão *" v-model.trim="$v.nome.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.nome.$invalid">Campo descrição é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-select label="Tipo *" :opcoes="listaTiposCanais" v-model.trim="$v.tipo.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.tipo.$invalid">Selecione um tipo de canal</p>
                    </b-col>

                </b-row>

                <template>

                    <b-row cols="3" v-if="nomeTipo === 'whatsapp'">
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" tipo="number" label="Numero *" v-model.trim="$v.telefone.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.telefone.$invalid">Campo numero é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="Login *" v-model.trim="$v.login.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.login.$invalid">Campo login é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" tipo="password" label="Senha *" v-model.trim="$v.senha.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.senha.$invalid">Senha é obrigátório</p>
                        </b-col>
                    </b-row>

                    <b-row cols="3" v-if="nomeTipo === 'webwhats'">
                        <b-col class="mb-3">
                            <input-select label="Habilitar? *" :opcoes="opcoesBoleanas" v-model.trim="habilitaWhatsWeb" />
                            <!--<input-padrao classes="m-0" tipo="number" label="Habilitar? *" v-model.trim="$v.telefone.$model" />-->
                            <p class="txt-erro-validacao" v-if="clickSalvar ">Campo  é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3" style="align-self: flex-end;">
                            <input-padrao classes="m-0" label="URL/Caminho *" v-model.trim="URLWathsWeb" />
                            <p class="txt-erro-validacao" v-if="clickSalvar ">Campo  é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3" style="align-self: flex-end;">
                            <input-padrao classes="m-0" label="WebHookWhats *" v-model.trim="HookWhatsWeb" />
                            <p class="txt-erro-validacao" v-if="clickSalvar ">Campo  é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3" style="align-self: flex-end;">
                            <input-padrao classes="m-0" label="Session *" v-model.trim="SessionWhatsWeb" />
                            <p class="txt-erro-validacao" v-if="clickSalvar ">Campo  é obrigátório</p>
                        </b-col>

                    </b-row>

                    <b-row cols="2" v-if="['facebook', 'instagram'].includes(nomeTipo)">
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="ID da Página *" v-model.trim="$v.facebookId.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.facebookId.$invalid">ID da Página é obrigatório</p>
                        </b-col>
                    </b-row>

                    <b-row cols="2" v-if="['ml pergunta', 'ml mensagem'].includes(nomeTipo)">
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="Conta Mercadolivre ID" v-model.trim="$v.codigoExterno.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.codigoExterno.$invalid">Conta Mercadolivre ID é obrigatório</p>
                        </b-col>
                    </b-row>

                    <b-row cols="2" v-if="nomeTipo === 'sms'">
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="SMSApp Key *" v-model.trim="$v.appKey.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.appKey.$invalid">Este campo é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="SMSkeyword *" v-model.trim="$v.keyword.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.keyword.$invalid">Este campo é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="SMSNumero Recive *" v-model.trim="$v.numeroRecive.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.numeroRecive.$invalid">Este campo é obrigátório</p>
                        </b-col>
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="SMSNumero Send *" v-model.trim="$v.numeroSend.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.numeroSend.$invalid">Este campo é obrigátório</p>
                        </b-col>
                    </b-row>
                    <b-row cols="2" v-if="nomeTipo === 'telegram'">
                        <b-col class="mb-3">
                            <input-padrao classes="m-0" label="Token telegram *" v-model.trim="$v.token_telegram.$model" />
                            <p class="txt-erro-validacao" v-if="clickSalvar && $v.token_telegram.$invalid">Este campo é obrigátório</p>
                        </b-col>

                    </b-row>

                </template>

            </b-container>

            <div class="w-100 text-right mt-4">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharModal">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvarCanal">Salvar</botao-modal-ok>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import { required, requiredIf } from "vuelidate/lib/validators";
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'

    export default {
        name: 'UsuarioFormPopup',
        components: {
            InputPadrao,
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel
        },
        props: {
            tiposCanais: Array,
        },
        data() {
            return {
                id: '',
                nome: '',
                tipo: '',

                codigoExterno: '',

                telefone: '', //whatsapp
                login: '',
                senha: '',
                facebookId: '', //facebook

                appKey: '', //SMS
                keyword: '',
                numeroRecive: '',
                numeroSend: '',

                token_telegram: '',//telegram

                habilitaWhatsWeb: false, // WhatsApp Web,
                URLWathsWeb: '', 
                HookWhatsWeb: '', 
                SessionWhatsWeb: '', 
                

                opcoesBoleanas: [{ value : 1, text : "Sim" }, { value : 0, text : "Não"}],

                clickSalvar: false
            }
        },
        computed: {
            listaTiposCanais: function () {
                if (this.tiposCanais) {
                   
                    return this.tiposCanais.map(prop => ({
                        value: prop.Value,
                        text: prop.Text
                    }))
                }
                return [];
            },
            nomeTipo: function () {
                if (this.tipo) {
                    return this.listaTiposCanais?.find(x => x.value === Number(this.tipo))?.text?.toLowerCase();
                }
                return ''
            },
        },
        methods: {
            showModal() {
                this.$refs['form-canal'].show()
            },
            editarCanal: function (canal) {
                this.id = canal.Id;
                this.nome = canal.Nome;
                this.tipo = canal.Tipo;

                this.codigoExterno = canal.CodigoExterno;

                this.telefone = canal.Telefome;
                this.login = canal.APILogin;
                this.senha = canal.APISenha;

                this.facebookId = canal.FacebookID;

                this.habilitaWhatsWeb = canal.habilitaWhatsWeb;
                this.URLWathsWeb = canal.URLWathsWeb;
                this.HookWhatsWeb = canal.HookWhatsWeb;
                this.SessionWhatsWeb = canal.SessionWhatsWeb;
                this.appKey = canal.SMSAppKey;
                this.keyword = canal.SMSkeyword;
                this.numeroRecive = canal.SMSNumeroRecive;
                this.numeroSend = canal.SMSNumeroSend;

                this.token_telegram = canal.TokenTelegram;
            },
            validarCampoTipoEspecifico(tipo) {
                return this.nomeTipo === tipo ? true : false
            },
            fecharModal: function () {
                this.$refs['form-canal'].hide();
                this.limparFormulario();
            },
            limparFormulario: function () {
                this.clickSalvar = false;

                this.id = '';
                this.nome = '';
                this.tipo = '';
                this.codigoExterno = '';
                this.telefone = '';
                this.login = '';
                this.senha = '';
                this.facebookId = '';
                this.habilitaWhatsWeb = this.habilitaWhatsWeb == "1" ? true: false;
                this.URLWathsWeb = "";
                this.HookWhatsWeb = "";
                this.SessionWhatsWeb = "";
                this.appKey = '';
                this.keyword = '';
                this.numeroRecive = '';
                this.numeroSend = '';
                this.token_telegram = '';
            },
            salvarCanal: function () {
                this.clickSalvar = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }

                let canal = {
                    "Id": this.id,
                    "Nome": this.nome,
                    "Tipo": Number(this.tipo),
                    "CodigoExterno": this.codigoExterno,
                    "Telefone": this.telefone,
                    "APILogin": this.login,
                    "APISenha": this.senha,
                    "FacebookID": this.facebookId,
                    "SMSAppKey": this.appKey,
                    "SMSkeyword": this.keyword,
                    "SMSNumeroRecive": this.numeroRecive,
                    "SMSNumeroSend": this.numeroSend,
                    "TokenTelegram": this.token_telegram,
                    "HabilitaWhatsWeb": this.habilitaWhatsWeb == "1" ? true : false,
                    "URLWathsWeb": this.URLWathsWeb,
                    "HookWhatsWeb": this.HookWhatsWeb,
                    "SessionWhatsWeb" : this.SessionWhatsWeb
                }

                this.$store.dispatch(`canais/${this.id ? 'editar' : 'adicionar'}`, canal);
                this.fecharModal();
            }
        },
        validations: {
            nome: { required },
            tipo: { required },

            codigoExterno: { required: requiredIf(function () { return ['ml pergunta', 'ml mensagem'].includes(this.nomeTipo) }) },

            telefone: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('whatsapp') }) },
            login: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('whatsapp') }) },
            senha: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('whatsapp') }) },

            facebookId: { required: requiredIf(function () { return ['facebook', 'instagram'].includes(this.nomeTipo) }) },

            appKey: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('sms') }) },
            keyword: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('sms') }) },
            numeroRecive: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('sms') }) },
            numeroSend: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('sms') }) },
            token_telegram: { required: requiredIf(function () { return this.validarCampoTipoEspecifico('token_telegram') }) },
        }
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
        margin: 0;
    }

</style>