<template>
    <div class="d-inline-block">
        <b-button class="btn-tab-cinza" v-on:click="$emit('click')">{{texto}}</b-button>
    </div>
</template>

<script>
export default {
  name: 'BotaoTabCinza',
    props:
    {
        texto: String
    }

}
</script>

<style scoped>
    .btn-tab-cinza {
        cursor: pointer;
        background-color: silver;
        color: black;
        border: none;
        font-size: .8rem;
        padding: 2px 8px;
    }
        .btn-tab-cinza:focus {
            font-size: .8rem;
            padding: 2px 8px;
        }
</style>